import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IntroText from '../components/IntroText'
import NeurologistsForm from '../components/NeurologistsForm'
import Content, { HTMLContent } from '../components/Content'
import PageBackgroundImage from '../components/PageBackgroundImage'

export const NeurologistsPageTemplate = ({
    title,
    subHeading,
    backgroundImage,
    content,
    contentComponent,
}) => {
    const PageContent = contentComponent || Content

    return (
        <section
            className={cx('max-w-1264 mx-auto px-24 py-60 lg:px-32 lg:py-120')}
        >
            {backgroundImage && <PageBackgroundImage image={backgroundImage} />}

            <h1 className={cx('hd-jb text-purple-700 mb-32')}>{title}</h1>

            <div className={cx('md:flex md:justify-between')}>
                <div className={cx('md-d:mb-24 md:w-2/5')}>
                    {subHeading && <IntroText>{subHeading}</IntroText>}

                    <PageContent
                        className={cx('rich-text')}
                        content={content}
                    />
                </div>
                <div className={cx('bg-gray-100 p-24 md:w-1/2 lg:p-60')}>
                    <NeurologistsForm title={title} />
                </div>
            </div>
        </section>
    )
}

NeurologistsPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    subHeading: PropTypes.string,
    backgroundImage: PropTypes.object,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const NeurologistsPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout
            title={post.frontmatter.title}
            description={post.frontmatter.subHeading}
        >
            <NeurologistsPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                subHeading={post.frontmatter.subHeading}
                backgroundImage={post.frontmatter.backgroundImage}
                content={post.html}
            />
        </Layout>
    )
}

NeurologistsPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default NeurologistsPage

export const neurologistsPageQuery = graphql`
    query NeurologistsPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                subHeading
                backgroundImage {
                    extension
                    publicURL
                }
            }
        }
    }
`
