import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from './form/Field'
import Input from './form/Input'
import Textarea from './form/Textarea'
import SubmitButton from './form/SubmitButton'
import handleSubmit from './utils/handleSubmit'
import handleChange from './utils/handleChange'

const NeurologistsForm = ({ title }) => {
    const [values, setValues] = useState({})

    return (
        <form
            name="neurologists"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-gtm-action={title}
            onSubmit={handleSubmit(values)}
        >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="neurologists" />
            <div hidden>
                <label>
                    Don’t fill this out:{' '}
                    <input
                        name="bot-field"
                        onChange={handleChange(setValues, values)}
                    />
                </label>
            </div>

            <Field>
                <Input
                    label="First Name"
                    name="firstName"
                    type="text"
                    handleChange={handleChange(setValues, values)}
                />
            </Field>

            <Field>
                <Input
                    label="Last Name"
                    name="lastName"
                    type="text"
                    handleChange={handleChange(setValues, values)}
                />
            </Field>

            <Field>
                <Input
                    label="Email"
                    name="email"
                    type="email"
                    handleChange={handleChange(setValues, values)}
                />
            </Field>

            <Field>
                <Input
                    label="Organization"
                    name="organization"
                    type="text"
                    handleChange={handleChange(setValues, values)}
                />
            </Field>

            <Field>
                <Input
                    label="How did you hear about Beacon?"
                    name="howHear"
                    type="text"
                    handleChange={handleChange(setValues, values)}
                    required={false}
                />
            </Field>

            <Field>
                <Textarea
                    label="Comments"
                    name="comments"
                    handleChange={handleChange(setValues, values)}
                    required={false}
                />
            </Field>

            <SubmitButton />
        </form>
    )
}

NeurologistsForm.propTypes = {
    title: PropTypes.string.isRequired,
}

export default NeurologistsForm
